import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';

import * as firebase from 'firebase';
import 'firebase/firestore';

import './edit-user.scss';

export default class AdminNewUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      savingUser: false,
      reportList: [],
      reportComponents: [],
      unCheckedReportComponents: [],
      allUsers: [],
      userComponents: null,
      userData: {
        data: {
          first_name: '',
          last_name: '',
          email: '',
          report_access_ids: [],
        },
        id: ''
      }
        
    }

    this.users = [];
  }

  async componentDidMount() {
    await this.buildReportList();
    await this.buildUserList();
  }

  saveUser = async () => {

    this.setState({ savingUser: true });
    
    var inputsValid = true;
    $('input').each(function() {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });
    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ savingUser: false });
      return;
    }

    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(this.state.userData.id).get();
      var docData = {
        first_name: this.state.userData.data.first_name,
        last_name: this.state.userData.data.last_name,
        report_access_ids: this.state.userData.data.report_access_ids
      };
      console.log(doc.data())
      console.log(docData);
      doc.exists 
        ? await db.collection('users').doc(this.state.userData.id).update(docData)
        : await db.collection('users').doc(this.state.userData.id).set(docData);
    } catch (err) {
      console.log(err);
    }
    toast.success('User saved!');
    this.setState({ savingUser: false });
  }

  clearinputs = () => {
    $('input').each(function() {
      $(this).val('');
    });
    $('.report-checkbox').each(function() {
      $(this).prop('checked', false);
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.saveUser();
    }
  }

  reportChanged = async (e) => {
    if (e.target.checked) {
      this.state.userData.data.report_access_ids.push(e.target.value)
    } else {
      var updatedReports = this.state.userData.data.report_access_ids.filter((item) => {
        return item !== e.target.value;
      });
      var user = this.state.userData;
      user.data.report_access_ids = updatedReports;
      this.setState({ userData: user });
    }
    console.log(this.state.userData.data.report_access_ids);
  }

  usernameChange = async (e) => {
    var email = e.target.value;
    console.log(email);
    let _this = this;
    if (email === '') {
      this.setState({
        userData: {
          data: {
            first_name: '',
            last_name: '',
            email: ''
          },
          id: ''
        }
      });
    } else {
      await this.setState({ reportComponents: this.state.unCheckedReportComponents })
      var currentUser = this.state.allUsers[email];
      console.log(currentUser);
      this.setState({ userData: currentUser })
      let reports = []
      $('.report-iterate').each(function (index, v) {
        let checkbox = $('.report-checkbox')[index];
        let value = $(checkbox).val();
        console.log(value);
        if (currentUser.data.report_access_ids.includes(value)) {
          $(checkbox).prop('checked', true);
          reports.unshift(
            <FormGroup value={value} className="site-iterate" key={value} check>
              <Label check>
                <Input type="checkbox" value={value} className="site-checkbox" onClick={_this.reportChanged} />
                {this.children[0].innerText}
              </Label>
            </FormGroup>
          );
        } else {
          $(checkbox).prop('checked', false);
          reports.push(
            <FormGroup value={value} className="site-iterate" key={value} check>
              <Label check>
                <Input type="checkbox" value={value} className="site-checkbox" onClick={_this.reportChanged} />
                {this.children[0].innerText}
              </Label>
            </FormGroup>
          );
        }
      })
      this.setState({ reportComponents: reports })
    }
  }

  mapReport = (report, id) => {
    var report = {
      data: report,
      id: id
    }
    return report
  }

  buildReportList = async () => {
    var db = firebase.firestore();
    var collection = await db.collection('reports').get();
    var reports = collection.docs.map(doc => this.mapReport(doc.data(), doc.id));
    reports.sort((a, b) => (a.display_name > b.display_name) ? 1 : -1);
    this.setState({ reportList: reports });

    var reportComponents = []
    reports.forEach((report) => {
      reportComponents.push(
        <FormGroup value={report.id} className="report-iterate" key={report.id} check>
          <Label check>
            <Input type="checkbox" value={report.id} className="report-checkbox" onClick={this.reportChanged}/>
            {report.data.display_name}
          </Label>
        </FormGroup>
      );
    });

    this.setState({ reportComponents, unCheckedReportComponents: reportComponents });
  }

  mapUser = (data, id) => {
    var user = {
      data: data,
      id: id
    }
    return user;
  }

  buildUserList = async () => {
    var db = firebase.firestore();
    var collection = await db.collection('users').get();
    var users = collection.docs.map(doc => this.mapUser(doc.data(), doc.id));
    var userComponents = []
    var usersDict = {}
    userComponents.push(
      <option key='none'></option>
    );
    users.sort((a, b) => (a.email > b.email) ? 1 : -1);
  
    users.forEach((user) => {
      userComponents.push(
        <option key={user.email}>{user.data.email}</option>
      )
      usersDict[user.data.email] = user
    });

    this.setState({ userComponents, allUsers: usersDict });
    }

  handleTextChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState(prevState => ({ userData: {...prevState.userData, [name]: value}} ));
  }

  render() {   
    return (
      <div className="admin-edit-user">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Edit User</div>
            <div className="subtitle">Select a user from the list to edit</div>

            <FormGroup>
              <Label>Select username</Label>
              <Input type="select" name="select" id="select_username" className="select-box" onChange={this.usernameChange}>
                {this.state.userComponents}
              </Input>
            </FormGroup>

            <div className={this.state.userData.data.email === '' ? 'hidden' : ''}>
              <div className="form-group">
                <div className="input-title">First Name</div>
                <Input type="text" 
                  className="text-input"
                  placeholder="First Name" 
                  onChange={this.handleTextChange} 
                  onKeyDown={this.handleKeyPress}
                  defaultValue={this.state.userData.data.first_name}
                  name='first_name'
                />
              </div>
              <div className="form-group">
                <div className="input-title">Last Name</div>
                <Input type="text"
                  className="text-input"
                  placeholder="Last Name" 
                  onChange={this.handleTextChange} 
                  onKeyDown={this.handleKeyPress}
                  defaultValue={this.state.userData.data.last_name}
                  name='last_name'
                />
              </div>

              <div className="form-group">
                <div className="input-title">Report Access</div>
                <div className="sites">
                  {this.state.reportComponents}
                </div>
              </div>

              <Button className="create-user-button" 
                onClick={this.saveUser}
                disabled={this.state.savingUser}
              >
                {this.state.savingUser ?
                  <div>
                    <b>Saving User     </b>
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>  
                  :
                  <div>
                    <b>Save User</b>
                  </div>
                }
              </Button>
            </div>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}
import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../components/header/header';

import * as firebase from 'firebase';
import User from '../../classes/user';

import './login.scss';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
      }
  }

  componentWillMount() {
    sessionStorage.clear();
  }

  loginPressed = async () => {

    if (this.state.email === '') {
      toast.error('Email cannot be blank!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      return;
    } else if (this.state.password === '') {
      toast.error('Password cannot be blank!');
      return;
    }

    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
    .then(async (response) => {
      sessionStorage.setItem('uid', response.user.uid);
      await User.checkIfAdmin();
      this.props.history.push('/home');
    })
    .catch((err) => {
      if (err.message) {
        toast.error(err.message, {
          autoClose: 5000
        });
      } else {
        toast.error('An unknwon error has occured. Please try again. If the issue persists please contact our support team.', {
          autoClose: 5000
        });
      }
      sessionStorage.clear();
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.loginPressed();
    }
  }

  render() {
    return (
      <div className="login-page">
        <Header history={this.props.history} />
        <div className="login-container">
          <div className="login-box">
            <div className="login-title">
              Login
            </div>
            <div className="login-text">
              Login using your email address and password
            </div>
            <Input type="text" 
              placeholder="Email" 
              onChange={(event) => {this.setState({email: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />
            <Input type="password" 
              placeholder="Password" 
              onChange={(event) => {this.setState({password: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />
            <Button className="login-button" 
              onClick={this.loginPressed}
            >
              <b>Login</b>
            </Button>
            <Button color="link"
              onClick={() => {this.props.history.push('/forgot-password')}}
            >
              Forgot password?
            </Button>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}
import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';
import './update-report.scss';
import ReactTooltip from 'react-tooltip';
import * as firebase from 'firebase';
import 'firebase/firestore';

export default class AdminUpdateReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatingReport: false,
      reports: [],
      reportData: {
        display_name: '',
        report_id: '',
        group_id: '',
        db_id: ''
      },
      reportComponents: [],
    }
    this.initReportData = {
      display_name: '',
      report_id: '',
      group_id: '',
      db_id: ''
    }
  }

  componentDidMount() {
    this.getReports();
  }


  buildReportComponents = async () => {
    var reportComponents = [];
    reportComponents.push(<option key="default"></option>)
    this.state.reports.forEach((report) => {
      reportComponents.push(
        <option key={report.id}>{report.data.display_name}</option>
      )
    })
    await this.setState({ reportComponents: reportComponents });
  }

  mapReport = (report, id) => {
    var report = {
      data: report,
      id: id
    }
    return report
  }

  getReports = async () => {
    var db = firebase.firestore();
    var collection = await db.collection('reports').get();
    var reports = collection.docs.map(doc => this.mapReport(doc.data(), doc.id));
    this.setState({ reports: reports })
    await this.buildReportComponents();
  }

  reportChanged = async (e) => {
    let name = '';
    if (e != null) {
      name = e.target.value;
    }
    if (name === '') {
      this.setState({
        reportData: {
          name: '',
          report_id: '',
          group_id: '',
          db_id: ''
        }
      })
    }
    else {
      $('#name').prop('disabled', false);
      $('#id').prop('disabled', false);
      $('#group_id').prop('disabled', false);
      for (var i in this.state.reports) {
        var report = this.state.reports[i];
        if (report.data.display_name === name) {
          // for sending to controller
          this.setState({
            reportData: {
              display_name: report.data.display_name,
              report_id: report.data.report_id,
              group_id: report.data.group_id,
              db_id: report.id
            }
          })
          // for updating input vals
          $('#name:text').val(report.data.display_name);
          $('#id:text').val(report.data.report_id);
          $('#group_id:text').val(report.data.group_id);
          this.setState({ selectedIndex: i });
          break
        }
      }
    }
  }

  handleTextChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState(prevState => ({ reportData: { ...prevState.reportData, [name]: value } }));
  }

  updateReport = async () => {
    this.setState({ updatingReport: true });
    var inputsValid = true;
    $('input').each(function () {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });
    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ savingUser: false });
      return;
    }

    try {
      var db = firebase.firestore();
      var docData = {
        display_name: this.state.reportData.name,
        report_id: this.state.reportData.report_id,
        group_id: this.state.reportData.group_id,
      };
      db.collection('reports').doc(this.state.reportData.db_id).update(docData);
      var reports = this.state.reports;
      reports[this.state.selectedIndex].data = docData;
      await this.setState({ reportData: this.initReportData });
      await this.setState({ reports: reports });
      await this.setState({ reportComponents: [] })
      await this.buildReportComponents();
    } catch (err) {
      console.log(err);
    } 
    toast.success('Report updated!');
    this.setState({ updatingReport: false });
  }

  deleteReport = async () => {
    this.setState({ updatingReport: true })
    var confirm = window.confirm(`Are you sure you want to delete ${this.state.reportData.display_name}?`);

    if (!confirm) {
      this.setState({ updatingReport: false })
      return;
    }

    try {
      var db = firebase.firestore();
      await db.collection('reports').doc(this.state.reportData.db_id).delete();
      var filteredReports = this.state.reports.filter(item => item.data.display_name !== this.state.reportData.display_name);
      await this.setState({ reportData: this.initReportData });
      await this.setState({ reports: filteredReports });
      await this.buildReportComponents();
      toast.success('Report deleted!');
    } catch (err) {
      console.log(err);
      toast.error('Failed to delete report!')
    } 
    this.setState({ updatingReport: false });

  }


  render() {
    return (
      <div className="admin-update-report">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Update a Report</div>
            <div className="subtitle">Please select a report to update</div>
            <FormGroup>
              <Label>Report Release</Label>
              <Input type="select" name="select" id="select_report" onChange={this.reportChanged}>
                {this.state.reportComponents}
              </Input>
            </FormGroup>
            <div className={this.state.reportData.display_name === '' ? 'hidden' : ''}>
              <div className="form-group">
                <Label data-tip='group' data-for='group' className='input-title'>Report Name</Label>
                <ReactTooltip id='group'>
                  <p>Name of report in database</p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="Report Group"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='name'
                  id='name'
                />
              </div>

              <div className="form-group">
                <Label data-tip='powerid' data-for='powerid' className="input-title">PowerBI Report ID</Label>
                <ReactTooltip id='powerid'>
                  <p>Report ID from PowerBI. Value is in the url of the report.<br />
                    EX. https://app.powerbi.com/groups/groupid/reports/<b>reportid</b>/ReportSection
                 </p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="Report ID"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='id'
                  id='id'
                />
              </div>

              <div className="form-group">
                <Label data-tip='powergid' data-for='powergid' className="input-title">PowerBI GroupID</Label>
                <ReactTooltip id='powergid'>
                  <p>Group ID from PowerBI. Value is in the url of the report.<br />
                    EX. https://app.powerbi.com/groups/<b>groupid</b>/reports/reportid/ReportSection
                 </p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="GroupID"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='group_id'
                  id='group_id'
                />
              </div>
              <div className="button-container">
                  <Button className="update-report-button"
                    onClick={this.updateReport}
                    disabled={this.state.updatingReport}
                  >
                    {this.state.updatingReport ?
                      <div>
                        <b>Update Report     </b>
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                      :
                      <div>
                        <b>Update Report</b>
                      </div>
                    }
                  </Button>

                  <div className="spacer" />

                  <Button className="delete-report-button"
                    onClick={this.deleteReport}
                    disabled={this.state.updatingReport}
                  >
                    {this.state.updatingReport ?
                      <div>
                        <b>Delete Report     </b>
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                      :
                      <div>
                        <b>Delete Report</b>
                      </div>
                    }
                  </Button>
              </div>

            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Button} from 'reactstrap';
import Header from '../../components/header/header';

import './not-found.scss';

export default class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    returnHome = () => {
        this.props.history.push('/home');
    }

    render() {
        return (
            <div className="not-found">
                <Header history={this.props.history} />
                <div className="page-container">
                    <div className="page-box">
                        <p className="page-title">404 Error!</p>
                        <p className="page-text">The desired page was not found.</p>
                        <Button className="home-button" onClick={this.returnHome}><b>Return Home</b></Button>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { Button, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';

import * as firebase from 'firebase';
import 'firebase/firestore';

import './new-user.scss';

export default class AdminNewUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      creatingUser: false,
      reportList: [],
      checkedReportIds: [],
      reportsLoading: true
    }

    this.baseState = this.state;
  }

  async componentDidMount() {
    await this.buildReportList();
  }

  createUser = async () => {
    console.log(this.state);
    this.setState({ creatingUser: true });
    
    var inputsValid = true;
    $('.text-input').each(function () {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });

    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ creatingUser: false });
      return;
    }

    if (this.state.password !== this.state.confirm_password) {
      toast.error('Password do not match. Please try again.');
      this.setState({ creatingUser: false });
      return;
    }
    let id = sessionStorage.getItem('uid');
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(async (response) => {
      try {
        var db = firebase.firestore();
        var doc = await db.collection('users').doc(response.user.uid).get();
        var admin = this.state.admin == null ? false : this.state.admin
        var docData = {
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          report_access_ids: this.state.checkedReportIds,
          admin: admin
        };
        doc.exists 
          ? db.collection('users').doc(response.user.uid).update(docData)
          : db.collection('users').doc(response.user.uid).set(docData);
      } catch (err) {
        console.log(err);
      }
      toast.success('User created!');
      this.resetUser(id);
      this.clearinputs();
      this.setState({
        checkedReportIds: [],
        creatingUser: false,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: ""
      });
    })
    .catch((err) => {
      toast.error(err.message);
      this.setState({ creatingUser: false });
      });
  }

  resetUser = (id) => {
    // Creating a user signs you in as them so we just set the uid back to the original users
    sessionStorage.setItem('uid', id);
  }

  clearinputs = () => {
    $('.text-input').each(function() {
      $(this).val('');
    });
    $('.site-iterate').each(function (index, v) {
      let checkbox = $('.checkbox')[index];
      $(checkbox).prop('checked', false);
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.createUser();
    }
  }

  reportChanged = async (e) => {
    if (e.target.checked) {
      this.state.checkedReportIds.push(e.target.value);
    } else {
      this.state.checkedReportIds = this.state.checkedReportIds.filter((item) => {
        return item !== e.target.value;
      });
    }
    console.log(this.state.checkedReportIds);
  }

  mapReport = (report, id) => {
    var report = {
      data: report,
      id: id
    }
    return report
  }

  buildReportList = async () => {
    var db = firebase.firestore();
    var collection = await db.collection('reports').get();
    var reports = collection.docs.map(doc => this.mapReport(doc.data(), doc.id));
    var reportComps = [];

    reports.forEach((report) => {
      reportComps.push(
        <FormGroup className="site-iterate" key={report.id} check>
          <Label check>
            <Input type="checkbox" className="checkbox" value={report.id} onClick={this.reportChanged} />
            {report.data.display_name}
          </Label>
        </FormGroup>
      )
    });
    this.setState({ reportList: reportComps, reportsLoading: false });
  }

  setAdmin = (e) => {
    if (e.target.checked) {
      this.setState({ admin: true })
    }
    else {
      this.setState({ admin: false })
    }
  }

  render() {
    return (
      <div className="admin-new-user">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Create New User</div>
            <div className="subtitle">Fill out the form to create a new user</div>

            <div className="form-group">
              <div className="input-title">First Name</div>
              <Input type="text" 
                className="text-input"
                placeholder="First Name" 
                onChange={(event) => {this.setState({first_name: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <div className="input-title">Last Name</div>
              <Input type="text"
                className="text-input"
                placeholder="Last Name" 
                onChange={(event) => {this.setState({last_name: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <div className="input-title">Email Address</div>
              <Input type="text" 
                className="text-input"
                placeholder="Email Address" 
                onChange={(event) => {this.setState({email: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <div className="input-title">Password</div>
              <Input type="password" 
                className="text-input"
                placeholder="Password" 
                onChange={(event) => {this.setState({password: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <div className="input-title">Confirm Password</div>
              <Input type="password" 
                className="text-input"
                placeholder="Confirm Password" 
                onChange={(event) => {this.setState({confirm_password: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Report Access</div>
              {
                this.state.reportsLoading 
                  ? <div>
                    <Spinner style={{ color: '#F88E28' }} />
                    </div>
                : <div>
                  <div className="sites">
                    {this.state.reportList}
                    </div>
                  </div>
                }
            </div>

            <FormGroup className="" check>
              <Label check>
                <Input type="checkbox" className="checkbox" onClick={this.setAdmin} />
                Admin
              </Label>
            </FormGroup>

            <Button className="create-user-button" 
              onClick={this.createUser}
              disabled={this.state.creatingUser}
            >
              {this.state.creatingUser ?
                <div>
                  <b>Creating User     </b>
                  <i class="fas fa-spinner fa-spin"></i>
                </div>  
                :
                <div>
                  <b>Create User</b>
                </div>
              }
            </Button>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}
import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../components/header/header';
import SweetAlert from 'sweetalert2-react';

import * as firebase from 'firebase';

import './new-password.scss';

export default class NewPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      showAlert: false
    }
  }

  savePressed = async () => {
    if (this.state.oldPassword === '') {
      toast.error('Missing old password. Please enter your old password.');
      return;
    } else if (this.state.password === '' || this.state.confirmPassword === '') {
      toast.error('Missing password. Please enter a new password.');
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      toast.error('New passwords do not match. Please try again.');
      return;
    }

    firebase.auth().signInWithEmailAndPassword(firebase.auth().currentUser.email, this.state.oldPassword).then(() => {
      firebase.auth().currentUser.updatePassword(this.state.password).then(() => {
        this.setState({ showAlert: true })
      })
      .catch((err) => {
        toast.error(err.message);
      });
    })
    .catch((err) => {
      toast.error(err.message);
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.savePressed();
    }
  }

  render() {
    return (
      <div className="new-password-page">
        <SweetAlert
          show={this.state.showAlert}
          title="Password Updated"
          text="Your password has been successfully updated."
          type="success"
          confirmButtonText="Go home"
          confirmButtonColor="#38A2E8"
          onConfirm={() => {this.props.history.push('/home')}}
        />
        <Header history={this.props.history} />
        <div className="new-password-container">
          <div className="new-password-box">
            <div className="new-password-title">
              New Password
            </div>
            <div className="new-password-text">
                Enter your old password.
            </div>
            <Input type="password" 
              placeholder="Old Password" 
              onChange={(event) => {this.setState({oldPassword: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />

            <div className="new-password-text">
                Please enter your new password below.
            </div>
            <Input type="password" 
              placeholder="New Password" 
              onChange={(event) => {this.setState({password: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />
            <Input type="password" 
              placeholder="Confirm Password" 
              onChange={(event) => {this.setState({confirmPassword: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />
            <Button className="save-button"
              onClick={this.savePressed}
            >
              Save
            </Button>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}
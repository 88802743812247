import * as firebase from 'firebase';
import 'firebase/firestore';

export default class User {

  static async updateLoginTimestamp() {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      var docData = {
        last_login: firebase.firestore.Timestamp.now()
      };
      doc.exists 
        ? db.collection('users').doc(sessionStorage.getItem('uid')).update(docData)
        : db.collection('users').doc(sessionStorage.getItem('uid')).set(docData);
    } catch (err) {
      console.log(err);
    }
  }

  static async getReports() {
    try {
      var db = firebase.firestore();
      console.log(sessionStorage.getItem('uid'))
      var user = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      var reportIds = user.data().report_access_ids;
      var deletedIds = [];
      var reports = new Map();
      for (const id of reportIds) {
        var report = await db.collection('reports').doc(id).get();
        var data = report.data();
        if (data) {
          reports.set(id, report.data());
        }
        else {
          deletedIds.push(id);
        }
      }

      if (deletedIds.length > 0) {
        var newIds = reportIds.filter(val => !deletedIds.includes(val));
        await db.collection('users').doc(sessionStorage.getItem('uid')).update({ report_access_ids: newIds });
      }
      
      return reports;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async checkIfAdmin() {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      if (doc.data().admin === true) {
        sessionStorage.setItem('admin', 'true');
      }
      else if (doc.data().local_admin === true) {
        sessionStorage.setItem('local_admin', 'true');
      }
      else {
        sessionStorage.removeItem('admin');
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getReport(release) {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('reports').doc(release).get();
      return doc.data();
    }
    catch (e) {
      console.log(e);
    }
  }
}
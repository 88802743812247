import React, { Component } from 'react';
import { Button, Input, FormGroup, Spinner } from 'reactstrap';
import Report from 'powerbi-report-component';
import axios from 'axios';
import $ from 'jquery';
import Header from '../../components/header/header';
import User from '../../classes/user';

import './home.scss';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      noReports: false,
      loadedReport: false,
      token: '',
      url: '',
      id: '',
      reports: new Map(),
      reportComponents: [],
      selectedReport: "",

    }
    //trackPage(window.location.pathname + window.location.search)
  }

  async componentDidMount() {
    await this.setReports();
    this.handleReports();
  }

  getReportComponents = () => {
    let reportComponents = []
    reportComponents.push(<option key="default" />);
    for (const report of this.state.reports) {
      reportComponents.push(
        <option key={report[0]}>
          {report[1].display_name}
        </option>
      );
      this.setState({ reportComponents });
    }
  }

  showLoadingScreen = () => {
    $(".loading-screen").fadeIn(1000);
  }

  removeLoadingScreen = () => {
    $(".loading-screen").fadeOut(1000);
  }

  handleReports = async () => {
    var reports = this.state.reports;
    var numReports = reports.size;
    if (numReports === 0) {
      this.setState({ noReports: true })
      this.removeLoadingScreen();
    }
    else if (numReports === 1) {
      var selectedReport = reports.entries().next().value[1].display_name
      this.setState({ selectedReport });
      await this.getReport();
    }
    else {
      this.getReportComponents();
      this.removeLoadingScreen();
    }
  }

  setReports = async () => {
    let reports = await User.getReports();
    this.setState({ reports });
  }

  reportChanged = (e) => {
    this.setState({ selectedReport: e.target.value });
  }

  getReport = async () => {
    this.showLoadingScreen();
    let selectedReport;
    for (const report of this.state.reports) {
      if (this.state.selectedReport == report[1].display_name) {
        selectedReport = report[1];
        break;
      }
    }
    try {
      var response = await axios.get('https://gv4frplfsa.execute-api.us-east-1.amazonaws.com/dev/token/default', {
        params: {
          reportid: selectedReport.report_id,
          groupid: selectedReport.group_id
        },
        headers: {
          "x-api-key": "MktlxGN1KyaVXRLKZtBAB5zeY3ogrzP117ynUyjo"
        }
      })
      console.log(response);
      this.setState({ loading: false, token: response.data.pbi_token, url: response.data.url, id: response.data.id});
    }
    catch(error) {
      alert("Unexpected " + error);
      console.log(error);
    }
  }

  fullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = $('#container').get(0);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }

  reset = async () => {
    await this.setState({ selectedReport: "", token: "" })
  }

  render() {

    let contents = this.state.noReports ? <h3>You have access to no reports!</h3>
      : this.state.token === "" ? null
      : <Report
          embedType="report"
          tokenType="Embed"
          accessToken={this.state.token}
          embedUrl={this.state.url}
          embedId={this.state.id}
          permissions="View"
          extraSettings={{
            filterPaneEnabled: false
        }}
        onLoad={this.removeLoadingScreen}
        />;

    return (
      <div className="home-page">
        <Header history={this.props.history} />

        <div className="reportUtils">
          {this.state.selectedReport && this.state.token !== ""
            ? <div className="buttonContainer">
                <i onClick={this.fullscreen} id="fullscreen" class="fas fa-expand"></i>
            </div>
            : null
          }
          {this.state.reports.size > 1 && this.state.selectedReport && this.state.token !== ""
            ? <div className="backContainer"><button onClick={this.reset} className="backButton">Back to Reports</button></div> : null
          }
        </div>

        <div className="center">
          <div className="loading-screen">
            <div>
              <img src={require("../../assets/tfs6.jpg")} alt="RAAMS" className="logo-img" />
            </div>
            <div className="space">
              <Spinner style={{ color: '#F88E28' }} />
            </div>
          </div>
            {contents == null
              ? <div>
                <FormGroup>
                  <div className="title">Select a Report</div>
                  <div className="subtitle">You have access to multiple reports please select one.</div>
                  <Input type="select" name="select" id="select_username" onChange={this.reportChanged}>
                    {this.state.reportComponents}
                  </Input>
              </FormGroup>
              <Button className="submit-button" onClick={this.getReport}>Get Report</Button>
              </div>
            : <div id="container">{contents}</div>}
          </div>
      </div>
    );
  }
}